import type { CompletionModel, Frequency } from "docuchatcommontypes";

type CompletionModelMapper = {
  [K in CompletionModel]: string;
};

export const mapCompletionModel: CompletionModelMapper = {
  Gpt3: "GPT-3.5 Turbo",
  Gpt4: "GPT-4 Turbo",
  MistralLarge: "Mistral Large 2",
  Claude3_5Sonnet: "Claude 3.5 Sonnet",
  Claude3Haiku: "Claude 3 Haiku",
  Claude3Sonnet: "Claude 3 Sonnet",
  Gpt4o: "GPT-4o",
  Gpt4oMini: "GPT-4o mini",
  LLama3_1: "Llama 3.1 70B",
};

type FrequencyMapper = {
  [K in Frequency]: string;
};

export function mapFrequency(): FrequencyMapper {
  const t = useNuxtApp().$i18n.t;

  return {
    Never: t("base.frequency.never"),
    Once: t("base.frequency.once"),
    EveryTime: t("base.frequency.everyTime"),
  };
};
